<template>
  <WsMain>
    <WsFlex
      flexDirection="column"
      alignItems="center"
    >
      <!-- <WsText size="36">管理後台</WsText> -->
      <WsLogo size="100" />
      <WsContent class="mt-10">
        <WsErrorMessage
          v-if="pageErrorMessage"
          class="mt-10"
        >{{pageErrorMessage}}</WsErrorMessage>
        <ValidationObserver ref="form">
          <form
            ref="form"
            @submit.prevent="onSubmit()"
            class="mt-5"
            autocomplete="on"
          >
            <WsState
              arrange="column"
              ref="WsStateEmail"
              autofocus
              name="email"
              rules="required|email"
              :label="$t('account')"
              type="email"
              v-model="email.value"
              autocomplete
              :errorMessage="email.errorMessage"
            ></WsState>
            <WsState
              arrange="column"
              class="mt-10"
              ref="WsStatePassword"
              rules="required|min:6"
              label="Password"
              type="password"
              name="password"
              v-model="password.value"
              :errorMessage="password.errorMessage"
            ></WsState>
            <WsBtn
              full
              type="submit"
              :loading="loading"
              class="mt-50"
              block
            >
              {{$t('login')}}
            </WsBtn>
          </form>
        </ValidationObserver>
        <WsInfoSection
          class="mt-20"
          style="min-width:266px"
          link="hello@wasateam.com"
        ></WsInfoSection>
      </WsContent>
    </WsFlex>
  </WsMain>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    email: {
      value: "",
      errorMessage: null,
    },
    password: {
      value: "",
      errorMessage: null,
    },
    pageErrorMessage: null,
  }),

  methods: {
    async onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.loading = true;
      try {
        await this.$auth.login({
          email: this.email.value,
          password: this.password.value,
        });
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const errorMessage = error.response.data.message;
          this.pageErrorMessage =  this.$t(errorMessage);
          this.$refs.WsStatePassword.focus();
        } else {
          this.pageErrorMessage = this.$message["internet error."];
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>